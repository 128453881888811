import * as React from 'react'
import Layout from '../components/layout'

const RecordsPage = () => (
  <Layout>
    <div className='container main'>
      <div className='row'>
        <div className='sixteen columns post-overview'>
          <article className='post'>
            <div className='post-header'>
              <div className='post-title'>
                <h2 className='title-text'>
                  <a href='/post/detail?id=37&amp;lang=cz'>Výsledek hlasování z ČS 2020</a>
                </h2>
                <ul className='post-meta'>
                  <li>
                    <i className='icon-history'></i> 23.05.2021
                  </li>
                </ul>
              </div>
            </div>
            <div className='post-body'>
              <p>Do sekce dokumenty je přidán výsledek hlasování z členské schůze 2020 a volby předsedů</p>
              <a className='post-read' href='/post/detail?id=37&amp;lang=cz'>
                Čtěte dále →
              </a>
            </div>
          </article>
          <article className='post'>
            <div className='post-header'>
              <div className='post-title'>
                <h2 className='title-text'>
                  <a href='/post/detail?id=35&amp;lang=cz'>Zápis ze schůze 14.04.2021</a>
                </h2>
                <ul className='post-meta'>
                  <li>
                    <i className='icon-history'></i> 03.05.2021
                  </li>
                </ul>
              </div>
            </div>
            <div className='post-body'>
              <p>
                Do sekce dokumenty je uložen zápis ze schůze představenstva a kontrolní komise - příprava členské schůze
                za rok 2020 a návrh kandidátů na představenstva a kontrolní komise na 3-leté období od 24.05.2021
              </p>
              <a className='post-read' href='/post/detail?id=35&amp;lang=cz'>
                Čtěte dále →
              </a>
            </div>
          </article>
          <article className='post'>
            <div className='post-header'>
              <div className='post-title'>
                <h2 className='title-text'>
                  <a href='/post/detail?id=34&amp;lang=cz'>Zápis ze schůze představenstva 16.03.2012</a>
                </h2>
                <ul className='post-meta'>
                  <li>
                    <i className='icon-history'></i> 19.03.2021
                  </li>
                </ul>
              </div>
            </div>
            <div className='post-body'>
              <p>
                V sekci dokumenty je zápis s vyhodnocením písemné členské schůze s vyhodnocením hlasování o
                refinancování novým úvěrem Revit a další.
              </p>
              <a className='post-read' href='/post/detail?id=34&amp;lang=cz'>
                Čtěte dále →
              </a>
            </div>
          </article>
          <article className='post'>
            <div className='post-header'>
              <div className='post-title'>
                <h2 className='title-text'>
                  <a href='/post/detail?id=32&amp;lang=cz'>Zápis ze schůze představenstva 08.02.2021</a>
                </h2>
                <ul className='post-meta'>
                  <li>
                    <i className='icon-history'></i> 23.02.2021
                  </li>
                </ul>
              </div>
            </div>
            <div className='post-body'>
              <p>Zápis v sekci dokumenty ze schůze představenstva 08.02.2021</p>
              <a className='post-read' href='/post/detail?id=32&amp;lang=cz'>
                Čtěte dále →
              </a>
            </div>
          </article>
          <article className='post'>
            <div className='post-header'>
              <div className='post-title'>
                <h2 className='title-text'>
                  <a href='/post/detail?id=31&amp;lang=cz'>Zápis ze schůze představenstva 21.10.2020</a>
                </h2>
                <ul className='post-meta'>
                  <li>
                    <i className='icon-history'></i> 03.11.2020
                  </li>
                </ul>
              </div>
            </div>
            <div className='post-body'>
              <p></p>
              <a className='post-read' href='/post/detail?id=31&amp;lang=cz'>
                Čtěte dále →
              </a>
            </div>
          </article>

          <div className='pagination'>
            <ul>
              <li>
                <a href='#' className='first'>
                  « První
                </a>
              </li>

              <li>
                <a href='#' className='previouspostslink'>
                  ‹
                </a>
              </li>

              <li>
                <a href='#'>
                  <span className='current'>1</span>
                </a>
              </li>

              <li>
                <a href='/post/list?lp-page=2&amp;category=3&amp;lang=cz' className='page'>
                  2
                </a>
              </li>

              <li>
                <a href='/post/list?lp-page=3&amp;category=3&amp;lang=cz' className='page'>
                  3
                </a>
              </li>

              <li>
                <a href='/post/list?lp-page=4&amp;category=3&amp;lang=cz' className='page'>
                  4
                </a>
              </li>

              <li>
                <a href='/post/list?lp-page=2&amp;category=3&amp;lang=cz' className='nextpostslink'>
                  ›
                </a>
              </li>

              <li>
                <a href='/post/list?lp-page=16&amp;category=3&amp;lang=cz' className='last'>
                  Poslední »
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecordsPage
